<template>
  <router-link :to="path">
    <v-container style="padding:10px" grid-list-xs justify-center>
      <v-layout
        align-center
        justify-center
        row
      >
        <v-hover v-slot:default="{ hover }">
          <v-card class="rounded-card"
                  :elevation="hover ? 9:0"
                  max-width="300px"
                  align="center"
                  justify="center"
          >
            <v-img
              class="ma-2"
              :src="img"
              contain
            />
            <v-row align="center" justify="center">
              <span class="subjects">
                {{ headline }}
              </span>
            </v-row>
          </v-card>
        </v-hover>
      </v-layout>
    </v-container>
  </router-link>
</template>
<script>
export default {
  props: {
    headline: {
      type: String,
      default: '',
      required: true
    },
    description: {
      type: String,
      default: '',
      required: false
    },
    img: {
      type: String,
      default: '',
      required: false
    },
    path: {
      type: String,
      default: '',
      required: true
    },
  }
}
</script>
<style scoped lang="scss">
@import '@/styles/screen-sizes.scss';
.rounded-card {
  margin-top:auto;
  margin-bottom:auto;
  @include respond-to(less-smallest-screens) {height: 130px; width:130px; border-radius: 4.5px; }
  @include respond-to(smallest-screens) { height: 140px; width:140px; border-radius: 4.5px; }
  @include respond-to(small-screens) { height: 150px; width:150px;border-radius: 5.5px;}
  @include respond-to(medium-screens) { height:220px; width:220px;border-radius: 6.5px;}
  @include respond-to(large-screens) { height: 250px; width:250px;border-radius: 7.5px;}
  @include respond-to(largest-screens) { height:300px; width:300px; border-radius: 8.5px;}
}
.thumbnail {
  display: inline-block;
  max-width: 100%; /* only this one important */
  margin-bottom: 1rem;
  border: solid 4px #fefefe;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(10, 10, 10, 0.2);
  line-height: 0; }
.imgC{
  @include respond-to(less-smallest-screens) {height:60px; top:10px;}
  @include respond-to(smallest-screens) {height:60px;top:10px;}
  @include respond-to(small-screens) {height: 70px;top:10px;}
  @include respond-to(medium-screens) {height:100px;top:7.5px;}
  @include respond-to(large-screens) {height: 130px;top:8.5px;}
  @include respond-to(largest-screens) {height:140px; top:20px;}
  padding-bottom:20px;
  }
  .subjects{
  text-decoration: none;
  font-family: Oswald;
  display: inline-block;
  text-align:center;
  position:relative;
  padding-top:20px;
  @include respond-to(less-smallest-screens) {font-size:1.25em;}
  @include respond-to(smallest-screens) {font-size:1.3em;}
  @include respond-to(small-screens) {font-size:1.2em;}
  @include respond-to(medium-screens) {font-size:1.25em;}
  @include respond-to(large-screens) {font-size:1.5em; top:10px;}
  @include respond-to(largest-screens) {font-size:1.5em; top:35px;}
  }
a {
  text-decoration: none !important;
}
</style>
