<template>
  <v-container grid-list-xl text-xs-center fluid>
    <!--    <div  class="horizontal-divider">
      <p class="horizontal-divider-text">
        {{headline1}}
      </p>
    </div>
-->
    <v-layout
      justify-space-around
      wrap
      row
    >
      <v-flex v-for="topic in topics1"
              :key="topic.key"
              xs12
              sm6
      >
        <subtopic-card
          :headline="topic.headline"
          :description="topic.description"
          :img="topic.img"
          :path="topic.path"
        />
      </v-flex>
    </v-layout>
    <br>
    <v-layout
      justify-space-around
      wrap
      row
    >
      <v-flex v-for="topic in topics2"
              :key="topic.key"
              xs12
              sm6
      >
        <subtopic-card
          :headline="topic.headline"
          :description="topic.description"
          :img="topic.img"
          :path="topic.path"
        />
      </v-flex>
    </v-layout>
    <v-layout align-left
              justify-space-around
              wrap
              row
    >
      <v-flex v-for="topic in topics3"
              :key="topic.key"
              xs12
              sm6
      >
        <subtopic-card
          :headline="topic.headline"
          :description="topic.description"
          :img="topic.img"
          :path="topic.path"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import SubtopicCard from '../../views/SubtopicCard.vue'
export default {
  components: {
    SubtopicCard
  },
  data () {
    return {
      headline1: 'Conservative Forces',
      topics1: [
      {
        headline: "Gravitational Force", description: "The attractive force acting between bodies due to virtue of their mass.",
        img:"assets/one.svg", path: "physics/gravity"
      },
      { headline: "Elastic Force", description: "The restoring force exerted by an elastic material when it is stretched or compressed.",
        img: "assets/two.svg", path: "mechanics/elastic"
      },
      ],
      headline2:'Non-conservative Forces',
      topics2: [
        { headline: "Friction", description: "The force resisting the relative motion between two solid surface that are sliding against each other. ",
          img: "assets/three.svg", path: "mechanics/friction"
        },
        {
          headline: "Buoyant Force", description: "The upward force exerted by any fluid upon a body that is fully or partially immersed in it.",
          img: "assets/four.svg", path: "mechanics/buoyancy"
        },
        ]
    }
  },
  created () {
    this.$store.commit('navigation/changeTitle', 'Forces in Mechanics');
    this.$store.commit('navigation/changeMenu', 'Forces in Mechanics');
    let newTopics = [
      {title: 'Gravitational Force', img:'assets/number-1.svg', action: () => this.$router.push({path: 'physics/gravity'})},
      {title: 'Elastic Force', img:'assets/number-2.svg', action: () => this.$router.push({path: 'mechanics/elastic'})},
      {title: 'Friction', img:'assets/number-3.svg', action: () => this.$router.push({path: 'mechanics/friction'})},
      {title: 'Buoyant Force', img:'assets/number-4.svg', action: () => this.$router.push({path: 'mechanics/buoyancy'})}
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
  },
  methods: {
  }
}
</script>
<style scoped lang="scss">
@import 'src/styles/subtopic-menu.scss';

</style>
